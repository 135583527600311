<template>
    <layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                            <img src="@/assets/img/bau-logo-for-light.svg" height="35"/>
                        </div>
                        <h4 class="text-center mb-5" style="min-height: 50px;">
                            {{ $t('conservatory') }} {{ $t('application_system') }}
                        </h4>
                        <div class="fadeIn">
                            <ValidationObserver ref="form">
                                <div class="mx-auto">
                                    <ValidationProvider name="username" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('national_id')">
                                            <b-form-input type="text"
                                                          v-mask="'###########'"
                                                          v-model="loginForm.national_id"
                                                          :state="errors[0] ? false : null"/>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <ValidationProvider name="pin" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('pin')">
                                            <password-input
                                                v-model="loginForm.pin" :validateError="errors[0]"
                                                v-mask="'######'"/>
                                        </b-form-group>
                                    </ValidationProvider>
                                    <div class="d-flex justify-content-center mb-4">
                                        <b-button variant="primary" block @click="login()">
                                            {{ $t('login').toUpper() }}
                                        </b-button>
                                    </div>
                                    <div class="d-flex justify-content-center mb-4">
                                        <b-button variant="outline-primary" block @click="goToRegister">
                                            {{ $t('first_password_get') }}
                                        </b-button>
                                    </div>
                                    <div class="d-flex justify-content-center mb-4">
                                        <b-button variant="outline-secondary" block @click="$router.push('/conservatory/application/forgot/password')">
                                            {{ $t('forgot_my_password') }}
                                        </b-button>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
                <div class="col-12 m-4 p-4">
                    {{$t('conservatory_contact_info')}}
                </div>
            </div>
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                                        sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />
        </div>
    </layout>
</template>

<script>
    // Layout
    import Layout from '@/modules/conservatoryApplication/layout/Layout'

    // Component
    import PasswordInput from '@/components/elements/PasswordInput'
    import CommonModal from "@/components/elements/CommonModal";

    // Service
    import ConservatoryService from "@/services/ConservatoryService"

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import { VueRecaptcha } from 'vue-recaptcha'

    export default {
        components: {
            PasswordInput,
            Layout,
            VueRecaptcha,
            CommonModal,
            ValidationObserver,
            ValidationProvider
        },
        metaInfo() {
            return {
                title: this.$t("conservatory")+' '+this.$t('application_system'),
            };
        },
        data() {
            return {
                loginForm: {
                    national_id: null,
                    pin: null
                }
            }
        },
        mounted() {
            this.$store.dispatch('conservatoryApplication/initData');
            this.$store.dispatch('conservatoryApplication/isLoginGoIndex');
        },
        created(){
            this.$swal
                .fire({
                    icon: 'info',
                    html: this.$t('conservatory_login_info'),
                    showCancelButton: false,
                    confirmButtonText: this.$t('ok'),
                })
        },
        methods: {
            onCaptchaVerified(recaptchaToken) {
                this.loginForm.g_recaptcha_token = recaptchaToken;
                ConservatoryService.login(this.loginForm).then(response => {
                    let data = response.data.data;

                    // Data
                    if (data) {
                        this.$store.commit('conservatoryApplication/setData', data)
                    }
                    // National ID
                    if (this.loginForm.national_id) {
                        this.$store.commit('conservatoryApplication/setNationalID', this.loginForm.national_id)
                    }
                    // Pin
                    if (this.loginForm.pin) {
                        this.$store.commit('conservatoryApplication/setPin', this.loginForm.pin)
                    }
                }).then(() => {
                    this.$router.push('/conservatory/application/form');
                }).catch((e) => {
                    this.showErrors(e);
                }).finally(() => {
                    this.$refs.recaptcha.reset()
                })
            },

            async login() {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    this.$refs.recaptcha.execute()
                }
            },

            goToRegister() {
                this.$router.push('/conservatory/application/register');
            }
        }
    }
</script>
